<template>

  <div>
    <!-- Add New Sidebar -->
    <app-risk-config-sidebar
      :is-app-config-sidebar-active.sync="isAppRiskConfigSidebarActive"
      :data="appInfo"
      @updated="queryConfigs"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              :to="{ name: 'merchant-apps', query: { merchant_no: merchantNo }, params: { merchant_name: merchantName } }"
              class="mr-2"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              <span class="align-middle">Back</span>
            </b-button>
            <b-button
              variant="outline-primary"
              @click="addNew"
            >
              <feather-icon
                icon="PlusIcon"
              />
              <span class="align-middle">Add New</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="configs"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <!-- Column: Actions -->
        <template #cell(opt)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="Trash2Icon"
              class="cursor-pointer"
              size="16"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BButton,
} from 'bootstrap-vue'
import { showAlert } from '@/libs/tool'
import useMerchantsJs from './merchants'
import AppRiskConfigSidebar from './AppRiskConfigSidebar.vue'

const {
  fetchAppRcConfigList,
  removeAppRcConfig,
} = useMerchantsJs()

export default {
  components: {
    AppRiskConfigSidebar,

    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
  },
  data() {
    return {
      merchantNo: this.$route.query.merchant_no,
      merchantName: this.$route.params.merchant_name,
      appId: this.$route.query.app_id,
      appName: this.$route.params.app_name,
      configs: [],
      tableFields: [
        'type',
        'value',
        'extra',
        'opt',
      ],

      isAppRiskConfigSidebarActive: false,
      appInfo: {},
    }
  },
  watch: {
  },
  created() {
    this.queryConfigs()
  },
  methods: {
    queryConfigs() {
      fetchAppRcConfigList(
        this.appId,
        configs => {
          this.configs = configs
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    deleteConfirm(config) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteData(config)
        }
      })
    },
    deleteData(config) {
      removeAppRcConfig(
        config,
        () => {
          this.queryConfigs()
          showAlert(this, 'success', 'Deleted!', 'Configuration information deleted successfully')
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    addNew() {
      this.appInfo = {
        appId: this.appId,
      }
      this.isAppRiskConfigSidebarActive = true
    },
  },
}
</script>
