<template>
  <b-sidebar
    id="app-config-sidebar"
    :visible="isAppConfigSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initSidebarData"
    @change="(val) => $emit('update:is-app-config-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Risk Configuration
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <b-form class="p-2">

        <!-- Config Key -->
        <b-form-group label="Config Key" label-for="config-key">
          <v-select
            v-model="configKey"
            :options="configKeyOptions"
            :reduce="val => val.label"
            :clearable="false"
            :searchable="false"
            input-id="config-key"
          />
        </b-form-group>

        <div v-if="configKey === 'TONGDUN_ANTE_THRESHOLD'">
          <b-form-group label="TongDun Strategy" label-for="tongdun-strategy">
            <v-select
              v-model="tongdunStrategy"
              :options="tongdunStrategyOptions"
              :clearable="false"
              :searchable="false"
              input-id="tongdun-strategy"
            />
          </b-form-group>
        </div>

        <!-- Config Value -->
        <b-form-group v-if="configKey && configKey !== 'MAIN_SWITCH'" label="Threshold" label-for="config-value">
          <b-form-input
            id="config-value"
            v-model="configValue"
            type="number"
            min="0"
            autofocus
            trim
            lazy-formatter
            :formatter="numberFormatter"
          />
        </b-form-group>

        <!-- Config Value -->
        <b-form-group v-if="configKey === 'MAIN_SWITCH'" label="Extra" label-for="config-extra">
          <b-form-input
            id="config-extra"
            v-model="configExtra"
            autofocus
            trim
          />
        </b-form-group>

        <el-divider class="mt-3 mb-3" />

        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="!isFormValid"
            @click.prevent="validationForm"
          >
            Submit
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BButton, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showAlert } from '@/libs/tool'
import useMerchantsJs from './merchants'

const {
  listRiskConfigKeys,
  addAppRcConfig,
  fetchTongdunStrategies,
} = useMerchantsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  model: {
    prop: 'isAppConfigSidebarActive',
    event: 'update:is-app-config-sidebar-active',
  },
  props: {
    isAppConfigSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      appId: '',
      configKey: '',
      configValue: '',
      configExtra: '',

      tongdunStrategy: '',
      tongdunStrategyOptions: [],
      configKeyOptions: [],
      pagsmileThreshold: 0,
      amlThreshold: 0,
    }
  },
  computed: {
    isFormValid() {
      return (this.configKey !== '' && this.configValue !== '') || (this.configKey === 'TONGDUN_ANTE_THRESHOLD' && this.tongdunStrategy && this.configValue !== '')
    },
  },
  watch: {
    isAppConfigSidebarActive(val) {
      this.initSidebarData()
      if (!val) {
        return
      }
      this.appId = this.data.appId
    },
    configKey(key) {
      if (key === 'TONGDUN_ANTE_THRESHOLD') {
        const strategyOptions = []
        fetchTongdunStrategies(
          strategies => {
            Object.keys(strategies).forEach(item => {
              const option = {
                value: item,
                label: strategies[item],
              }
              strategyOptions.push(option)
            })
            this.tongdunStrategyOptions = strategyOptions
          },
          fail => {
            this.showAlert('warning', 'Fail!', `fetch Tongdun strategies fail with ${fail}`)
          },
        )
      }
    },
  },
  created() {
    listRiskConfigKeys(
      configKeys => {
        this.configKeyOptions = configKeys
      },
      fail => {
        this.showAlert('warning', 'Fail!', `fetch Config Keys fail with ${fail}`)
      },
    )
  },
  methods: {
    numberFormatter(value) {
      if (!value) {
        return ''
      }
      return parseInt(value, 0)
    },
    initSidebarData() {
      this.appId = ''
      this.configKey = ''
      this.configValue = ''
    },
    validationForm() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.addAppRcConfig()
        }
      })
    },
    addAppRcConfig() {
      const configKey = `${this.configKey}`
      let configValue = `${this.configValue}`
      let extra = ''
      let desc = ''
      if (configKey === 'TONGDUN_ANTE_THRESHOLD') {
        configValue = `${this.tongdunStrategy.label}:${this.configValue}`
        extra = `${this.tongdunStrategy.value}`
        desc = `${this.configValue}`
      }

      const params = {
        app_id: this.appId,
        type: configKey,
        value: configValue,
        extra,
        desc,
      }
      addAppRcConfig(
        params,
        () => {
          showAlert(this, 'success', 'Updated!', 'Success')
          this.$emit('update:is-app-config-sidebar-active', false)
          this.$emit('updated')
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
